import Cookies from 'js-cookie';
import psl from 'psl/dist/psl';

var CookieConsent = function (cookieName = 'cookies-allowed') {
  var _events = {};
  var secureCookies = (location.protocol === 'https:');
  var cookieWarningFormClass = '.cookie-warning__form';
  var cookieWarningFormContainerClass = '.cookie-warning';
  var consentAcknowledged = false;

  // Adapted from https://stackoverflow.com/a/30153391
  var serializeFormData = function (form) {
    var field, s = {};
    if (typeof form == 'object' && form.nodeName == "FORM") {
      var len = form.elements.length;
      for (var i = 0; i < len; i++) {
        field = form.elements[i];
        if (field.name && !field.disabled && field.type != 'file' && field.type != 'reset' && field.type != 'submit' && field.type != 'button') {
          if (field.checked) {
            s[field.name] = encodeURIComponent(field.value);
          }
        }
      }
    }
    s = JSON.stringify(s);
    return s;
  };

  var acceptedRevision = function () {
    var consentJson = Cookies.get(cookieName);
    var revision = 0;

    if (consentJson) {
      consentJson = JSON.parse(consentJson);
    }

    if (consentJson !== undefined && consentJson.accepted_revision !== undefined) {
      revision = consentJson.accepted_revision;
    }

    return revision;
  };

  var currentRevision = function () {
    var cookieWarningFormDataset = document.querySelector(cookieWarningFormContainerClass).dataset;
    var revision = parseInt(cookieWarningFormDataset.revision);

    if (isNaN(revision)) {
      revision = 0;
    }

    return revision;
  };

  var loadScripts = function (category) {
    var scriptElements = document.querySelectorAll('script[data-cookie-type="' + category + '"]');
    for (var i = 0, n = scriptElements.length; i < n; i++) {
      scriptElements[i].setAttribute('type', 'text/javascript');
      scriptElements[i].setAttribute('data-cookie-execute', 'true');

      var source = scriptElements[i].getAttribute('data-src');

      if(scriptElements[i].getAttribute('src') != null) {
        console.log('Invalid HTML detected, please use "data-src" instead:');
        console.dir(scriptElements[i].getAttribute('src'))
      }

      if (source == null) {
        eval(scriptElements[i].innerHTML);
      } else {
        // https://stackoverflow.com/a/28002292
        var script = document.createElement('script');
        var prior = document.getElementsByTagName('script')[0];
        script.async = 1;

        script.onload = script.onreadystatechange = function (_, isAbort) {
          if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
            script.onload = script.onreadystatechange = null;
            script = undefined;
          }
        };

        script.src = source;
        prior.parentNode.insertBefore(script, prior);
      }
    }
  };

  var loadIframes = function (category) {
    var iframeElements = document.querySelectorAll('iframe[data-cookie-type="' + category + '"]');
    for (var i = 0, n = iframeElements.length; i < n; i++) {
      iframeElements[i].setAttribute('data-cookie-execute', 'true');
      var src = iframeElements[i].getAttribute('data-src');
      iframeElements[i].setAttribute('src', src);
    }
  };

  var allowCookies = function (consents) {
    for (var property in consents) {
      if (!consents.hasOwnProperty(property)) {
        continue;
      }

      if (consents[property] == 'true') {
        var cookieCategory = property.replace('cookie_', '');
        loadScripts(cookieCategory);
        loadIframes(cookieCategory);
      }
    }

    trigger('complete', consents);
  };

  var saveConsent = function (consentJson) {
    consentJson = JSON.parse(consentJson);
    consentJson.accepted_string = new Date().toString();
    consentJson.accepted_revision = currentRevision();
    consentJson.version = '2021-10-28:standalone';

    var domain = window.location.hostname;
    var datasetDomain = document.querySelector(cookieWarningFormContainerClass).dataset.domain;

    if (datasetDomain !== undefined) {
      domain = datasetDomain;
    }

    Cookies.set(
      cookieName,
      JSON.stringify(consentJson),
      {
        sameSite: 'lax',
        expires: 365,
        secure: secureCookies,
        domain: domain
      }
    );

    allowCookies(consentJson);
  };

  var consentConfirmed = function () {
    // window.removeEventListener('scroll', consentConfirmed());

    if(consentAcknowledged == false) {
      consentAcknowledged = true;
      console.log('Consent acknowledged');
      var consentJson = serializeFormData(document.querySelector(cookieWarningFormClass));
      document.querySelector(cookieWarningFormContainerClass).classList.remove('cookie-warning--awaiting-confirmation');
      saveConsent(consentJson);
    }
  };

  var reset = function () {
    // https://stackoverflow.com/a/32263695
    // https://stackoverflow.com/a/27374628
    var expiry = 'expires=' + new Date().toUTCString();
    var path = 'path=/';
    var fqdomain = 'domain=.' + psl.parse(document.domain).domain;
    var docdomain = 'domain=.' + document.domain;

    document.cookie.split(";").forEach(function (c) {
      document.cookie = c.replace(/^ +/, '')
        .replace(/=.*/, '=;' + expiry + ';' + path);

      document.cookie = c.replace(/^ +/, "")
        .replace(/=.*/, '=;' + expiry + ';' + fqdomain + ';' + path);

      document.cookie = c.replace(/^ +/, "")
        .replace(/=.*/, '=;' + expiry + ';' + docdomain + ';' + path);
    });
  };

  var init = function () {
    var consentJson = Cookies.get(cookieName);

    if(consentJson) {
      consentJson = JSON.parse(consentJson);
    }

    trigger('init', consentJson);

    if (!document.querySelector(cookieWarningFormContainerClass)) {
      trigger('formRequired');
    }

    if (consentJson === undefined) {
      document.querySelector(cookieWarningFormContainerClass).classList.add('cookie-warning--awaiting-confirmation');

      var requireClick = document.querySelector(cookieWarningFormContainerClass).dataset.requireClick;
      if (requireClick === undefined) {
        window.addEventListener('scroll', function() { consentConfirmed() });
      }
    } else if (consentJson !== undefined && currentRevision() > acceptedRevision()) {
      trigger('reset');
      reset();
      init();
    } else {
      allowCookies(consentJson);
    }
  };

  var deactivate = function () {
    // Turbolinks re-appends the new head with the old, we want to remove what
    // we're done with so it does run twice, run twice it doesn't
    var elements = document.querySelectorAll('[data-cookie-execute=true]');
    for (var i = 0, n = elements.length; i < n; i++) {
      elements[i].remove();
    }
  };

  // https://stackoverflow.com/a/17165730
  var trigger = function (event, params) {
    var i;
    if (_events[event]) {
      for (i in _events[event])
        _events[event][i](params);
    }
  };

  this.on = function (event, callback) {
    if (!_events[event])
      _events[event] = [];
    _events[event].push(callback);
  };

  this.reset = function () { reset() };
  this.deactivate = function () { deactivate(); };
  this.init = function () {
    init();

    document.querySelector('.cookie-warning__button').addEventListener('click', function (event) {
      consentConfirmed();
      event.preventDefault();
    }, false);
  };
};

export default CookieConsent;
